@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-Thin.ttf);
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-ExtraLight.ttf);
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-Light.ttf);
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-Regular.ttf);
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-Medium.ttf);
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Epilogue";
  src: url(./assets/fonts/Epilogue/Epilogue-Bold.ttf);
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Epilogue";
  font-style: normal;
}
